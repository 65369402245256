import React from "react"
import Title from "../Title"
import serviceStyles from "../../css/services.module.css"
import services from "../../constants/services"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Services = () => {
  return (
    <section className={serviceStyles.services}>
      <Title title="" subtitle="services"/>
      <div className={serviceStyles.center}>
        {services.map((item, index) => {
          return <article key={index} className={serviceStyles.service}>
            <AniLink fade to="/contact" >
              <span>{item.icon}</span>
              <h4>{item.title}</h4>
              <p>{item.text}</p>
            </AniLink>
          </article>
        })}
      </div>
    </section>
  )
}

export default Services
