import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Layout from "../components/Layout"
import Banner from '../components/Banner'
import About from '../components/Home/About'
import Services from '../components/Home/Services'
import { graphql } from "gatsby"
import StyledHero from '../components/StyledHero'
import FeaturedProjects from '../components/Home/FeaturedProjects'
import SEO from '../components/SEO'

export default ({data}) => (
    <Layout>
      <SEO title="Home" description="This is the home page" />
      <StyledHero home="true" img={data.homeBg.childImageSharp.fluid}>
        <Banner title="NJ Audio" info="Composer, Producer &amp; Musician">
          <AniLink to="/portfolio" className="btn-white">
            Portfolio
          </AniLink>
        </Banner>
      </StyledHero>
      <About/>
      <Services/>
      <FeaturedProjects/>
    </Layout>
)

export const query = graphql`
    query {
        homeBg:file(relativePath:{eq:"sheetBg.jpg"}) {
            childImageSharp {
                fluid(quality:90, maxWidth:4160) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

