import React from "react"
import { GiFilmProjector, GiDrum, GiGuitar } from "react-icons/gi"

export default [
  {
    icon: <GiFilmProjector />,
    title: "Music for Media",
    text:
      "Music for TV, Film and Games.",
  },
  {
    icon: <GiDrum />,
    title: "Drum Programming",
    text:
      "Midi drum programming offered as raw midi or recorded using Hi-Quality drum samples to drop straight into your projects for mixing.",
  },
  {
    icon: <GiGuitar />,
    title: "Guitarist",
    text:
      "I can provide guitar duties for your project.  All styles catered for. ",
  },
]
