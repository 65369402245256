import React from "react"
import Project from "../Projects/Project"
import { useStaticQuery, graphql } from "gatsby"
import Title from "../Title"
import featuredStyles from "../../css/items.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const getProjects = graphql`
    query {
        featuredProjects:allContentfulProject(filter: {featured: {eq: true}}) {
            edges {
                node {
                    name
                    slug
                    category
                    contentful_id
                    image {
                        fluid {
                            ...GatsbyContentfulFluid_tracedSVG
                        }
                    }
                    video {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`

const FeaturedProjects = () => {
  const response = useStaticQuery(getProjects)
  const projects = response.featuredProjects.edges

  return (
    <section className={featuredStyles.projects}>
      <Title title="featured" subtitle="projects"/>
      <div className={featuredStyles.center}>
        {projects.map(({ node }) => {
          return <Project key={node.contentful_id} project={node}/>
        })}
      </div>
      <AniLink fade to="/portfolio" className="btn-primary">
        All Projects
      </AniLink>
    </section>
  )
}

export default FeaturedProjects
