import React from "react"
import bannerStyles from '../css/banner.module.css'

const Banner = ({ title, info, children }) => {
  return (
    <div className={bannerStyles.banner}>
      <h1>{ title }</h1>
      <p>{ info } </p>
      { children }
    </div>
  )
}

export default Banner
