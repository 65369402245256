import React from "react"
import Image from "gatsby-image"
import projectStyles from "../../css/project.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const getImage = graphql`
    query {
        file(relativePath: {eq: "defaultBg.jpg"}) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`

const Project = ({ project }) => {
  const data = useStaticQuery(getImage)
  const img = data.file.childImageSharp.fluid
  const { name, category, slug, image } = project

  let mainImage = image? image.fluid : img

  return (
    <article className={projectStyles.project}>
      <div className={projectStyles.imgContainer}>
        <Image fluid={mainImage} className={projectStyles.img} alt="Simgle Project"/>
        <AniLink fade to={`/portfolio/${slug}`} className={projectStyles.link}>details</AniLink>
      </div>
      <div className={projectStyles.footer}>
        <h3>{name || "Portfolio"}</h3>
        <div className={projectStyles.info}>
          <h6>{category || "Portfolio"}</h6>
        </div>
      </div>
    </article>
  )
}

Project.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    category: PropTypes.string.isRequired,
  }),
}

export default Project
