import React from "react"
import Title from "../Title"
import aboutStyles from "../../css/about.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import AniLink from "gatsby-plugin-transition-link/AniLink"

const getAbout = graphql`
    query aboutImage{
        aboutImage:file(relativePath:{eq:"aboutBg.jpg"}){
            childImageSharp{
                fluid(maxWidth:600){
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`

const About = () => {
  const { aboutImage } = useStaticQuery(getAbout)

  return (
    <section className={aboutStyles.about}>
      <Title title="about" subtitle="NJ"/>
      <div className={aboutStyles.aboutCenter}>
        <article className={aboutStyles.aboutImg}>
          <div className={aboutStyles.imgContainer}>
            <Img fluid={aboutImage.childImageSharp.fluid} alt="Looking through blinds"/>
          </div>
        </article>
        <article className={aboutStyles.aboutInfo}>
          <h4>Take a Peek</h4>
          <p>
            I can't really tell you where it all began for me as a musician as it has always been a part of my life.
          </p>
          <p>
            I have had and continue to have success as a touring artist signed to Season of Mist.
          </p>
          <p>
            I appreciate all music as a form of expression from 17th century Baroque to Modern day synthesis.
          </p>
          <AniLink fade to="/contact" className="btn-primary">
            Contact
          </AniLink>
        </article>
      </div>
    </section>
  )
}

export default About
